let backendHost;
let app_env;

const hostname = window && window.location && window.location.hostname;

if (hostname === "chat.peoplebox.ai") {
  backendHost = "https://api.peoplebox.ai";
  app_env = "PRODUCTION";
} else if (hostname === "chat.demo.peoplebox.ai") {
  backendHost = "https://api.demo.peoplebox.ai";
  app_env = "DEMO";
} else if (hostname === "chat.staging.peoplebox.ai") {
  backendHost = "https://api.staging.peoplebox.ai";
  app_env = "STAGING";
} else if (hostname === "chat.test.peoplebox.ai") {
  backendHost = "https://api.test.peoplebox.ai";
  app_env = "TEST";
} else if (hostname === "chat.apna.peoplebox.ai") {
  backendHost = "https://api.apna.peoplebox.ai";
  app_env = "APNA";
} else if (hostname === "chat.test.peoplebox.ai") {
  backendHost = "https://api.test.peoplebox.ai";
  app_env = "TEST";
} else if (hostname === "chat.alpha.peoplebox.ai") {
  backendHost = "https://api.alpha.peoplebox.ai";
  app_env = "ALPHA";
} else if (hostname === "chat.beta.peoplebox.ai") {
  backendHost = "https://api.beta.peoplebox.ai";
  app_env = "BETA";
} else if (hostname === "chat.eu.peoplebox.ai") {
  backendHost = "https://api.eu.peoplebox.ai";
  app_env = "PRODUCTION";
} else {
  backendHost = "http://localhost:3000";
  app_env = "LOCALHOST";
}

export const API_ROOT = `${backendHost}`;
export const APP_ENV = app_env;
